import { chatkeeperCredentials } from "@/includes/logic/Credentials/ChatkeeperCredentials";
import { KeeperRuntimeConfig } from "@/store/state";
import { CreatedCredential } from "@/includes/types/Credentials";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class LlmConditionsMixin extends Vue {
  credentials: Array<CreatedCredential> = []

  created() {
    return chatkeeperCredentials.getCredentialsApi({ target_id: this.$store.getters.chatId })
        .then(({ data }) => {
          this.credentials = data.credentials
        })
  }

  get credentialOptions(): Array<SelectOption> {
    return this.credentials.map(c => {
      return {
        label: c.title,
        value: c.key
      }
    })
  }

  credentialProviderModelOptions(credential: string): Array<SelectOption> {
    const cred = this.credentials.find(c => c.key === credential)

    if (cred) {
      return ((this.$store.state?.AdminTemplate?.runtime_config?.ai_models as KeeperRuntimeConfig['ai_models'] | undefined) ?? [])
          .filter(m => m.credential_provider_name === cred.provider)
          .map(pn => {
            return {
              label: pn.model_name,
              value: pn.model_name,
            }
          })
    } else {
      return []
    }
  }

}
