import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";
import ExpressionConditionSetupView from "./ExpressionConditionSetupView.vue";

const condition: EntityData = {
  'setupView': ExpressionConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.Expression,
    ...newBaseCondition,
    value: [ 'Contains' ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.Expression, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message ]
  },
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default condition
