var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ai-integration-module-open-ai-setup"},[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'token',
        'prefix': 'ai_integration_module_open_ai_params_',
        'validation': 'required',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }