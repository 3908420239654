






























































































































































import ActionGroupItems from "@/components/TriggerSetup/components/ActionsGroups/ActionGroupItems.vue";
import { ActionsType, TriggerData } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { TriggerFactory } from "@/components/TriggerSetup/logic/trigger/trigger-factory";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import List from "piramis-base-components/src/components/Pi/components/List/List.vue";
import { ListDirection } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";

import { cloneDeep } from "lodash";
import { Component, Mixins, Prop, Ref, VModel } from "vue-property-decorator";

@Component({
  'components': {
    List,
    BlockMovingControls,
    ActionGroupItems,
    NumberInput
  },
  data() {
    return {
      ListDirection
    }
  },
})
export default class ActionsGroups extends Mixins(ModelSetter) {
  @VModel({ 'type': Array, 'required': true }) model!: TriggerData['actions']

  @Prop({ type: String }) actionExecuteType!: 'Random' | 'All'

  @Prop({ 'type': Array,  'required': false,  'default': () => [] }) selectedFormStruct!: Array<MessageEditorWithMediaData>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, 'default': false }) readonly showFromTo!: boolean

  @Prop({ 'type': Boolean, 'default': false }) readonly showLimit!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  @Ref('actionGroupFromTo') fromTo!: HTMLDivElement

  triggerFactory = new TriggerFactory()

  isActionGroupExtraSettingModalOpen = false

  currentGroupInfo: any | null = null

  isExtraGroupActionButtonActive(group: any) {
    return group.chance !== 100 || group.from !== 0 || group.to !== 0
  }

  setTriggerGroupModel() {
    if (this.currentGroupInfo) {
      const { guid, value } = this.currentGroupInfo

      const group = this.model.find(g => g.guid === guid)

      if (group) {
        group.value.chance = value.chance
        group.value.from = value.from
        group.value.to = value.to
        group.value.limit = value.limit
      }

    }

    this.isActionGroupExtraSettingModalOpen = false

  }

  prepareActionGroupExtraSettings(group: any) {
    this.currentGroupInfo = cloneDeep(group)
    this.isActionGroupExtraSettingModalOpen = true
  }

  addActionGroup(actionGroups: TriggerData['actions']): void {
    actionGroups.push(this.triggerFactory.createActionGroup())
  }

  processOldActionTypes(action: EntityData) {
    if ([ ActionsType.ChangeReputationAction, ActionsType.ChangePointsAction ].includes(action.structure.type)) {
      switch (action.structure.type) {
        case ActionsType.ChangeReputationAction:
          action.structure.type = ActionsType.ChangeReputationIntervalAction
          break
        case ActionsType.ChangePointsAction:
          action.structure.type = ActionsType.ChangePointsIntervalAction
          break
      }
      if (action.structure.count !== undefined) {
        action.structure.from = Number(action.structure.count)
        action.structure.to = Number(action.structure.count)
        delete action.structure.count
      }
    }
  }

  created(): void {
    const actionGroups = this.model.slice()

    actionGroups.forEach((actionGroup) => {
      actionGroup.value.actions.forEach((action) => {
        if ([ ActionsType.ChangeReputationAction, ActionsType.ChangePointsAction ].includes(action.value.structure.type)) {
          this.processOldActionTypes(action.value)
        }
      })
    })
  }
}
