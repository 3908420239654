












import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import HttpRequestSetting from 'piramis-base-components/src/shared/components/HttpRequest/HttpRequestSetting.vue'
import { AvailableBaseTabs } from 'piramis-base-components/src/shared/components/HttpRequest/types'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { SelectOption } from "piramis-base-components/src/logic/types";
import { HttpMethodEnum } from "piramis-base-components/src/shared/types/HttpRequest";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    HttpRequestSetting,
  }
})
export default class HttpRequestActionSetupView extends Mixins(ActionSetupView) {
  get httpMethods(): Array<SelectOptionData> {
    return [
      {
        label: HttpMethodEnum.Get,
        value: HttpMethodEnum.Get,
      },
      {
        label: HttpMethodEnum.Post,
        value: HttpMethodEnum.Post,
      },
      {
        label: HttpMethodEnum.Put,
        value: HttpMethodEnum.Put,
      },
      {
        label: HttpMethodEnum.Delete,
        value: HttpMethodEnum.Delete,
      }
    ]
  }

  get requestFormatOptions(): Array<SelectOption> {
    return [
      {
        label: 'Json',
        value: 'json'
      },
      {
        label: 'Form',
        value: 'form'
      },
      {
        label: 'Xml',
        value: 'xml'
      },
      {
        label: 'Plain',
        value: 'plain'
      }
    ]
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('http_request_tab_headers').toString(),
        value: AvailableBaseTabs.headers,
        helpMessage: this.$t('http_request_tab_headers_help_message').toString()
      },
      {
        label: this.$t('http_request_tab_body').toString(),
        value: AvailableBaseTabs.body,
        helpMessage: this.$t('http_request_tab_body_chatkeeper_help_message').toString()
      },
    ]
  }

}
