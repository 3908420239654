var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-groups"},[_c('a-modal',{attrs:{"title":_vm.$t('trigger_action_group_extra_setting_button_title'),"ok-text":_vm.$t('save_button'),"after-close":function () { return _vm.currentGroupInfo = null; }},on:{"ok":_vm.setTriggerGroupModel},model:{value:(_vm.isActionGroupExtraSettingModalOpen),callback:function ($$v) {_vm.isActionGroupExtraSettingModalOpen=$$v},expression:"isActionGroupExtraSettingModalOpen"}},[(_vm.currentGroupInfo)?[(_vm.showLimit && _vm.model.length > 1)?_c('number-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.currentGroupInfo.value,
            'key': 'limit',
            'prefix': 'trigger_action_group_',
            'disabled': _vm.disabled,
          }
        }}}):_vm._e(),(_vm.showFromTo)?_c('div',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(_vm.currentGroupInfo.value.from === 0 && _vm.currentGroupInfo.value.to === 0),expression:"currentGroupInfo.value.from === 0 && currentGroupInfo.value.to === 0"}]},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.currentGroupInfo.value,
              'key': 'from',
              'prefix': 'trigger_action_group_',
              'disabled': _vm.disabled,
              'max': _vm.currentGroupInfo.value.to,
              'min': 0
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.currentGroupInfo.value,
              'key': 'to',
              'prefix': 'trigger_action_group_',
              'disabled': _vm.disabled,
              'min': _vm.currentGroupInfo.value.from,
              'max': 100,
            }
          }}}),_c('a-divider')],1):_vm._e(),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.currentGroupInfo.value,
            'key': 'chance',
            'prefix': 'trigger_action_group_',
            'disabled': _vm.disabled,
            'min': 0,
            'max': 100,
            'formatter': function (value) { return (value + "%"); },
            'parser': function (value) { return value.replace('%', ''); },
          }
        }}})]:_vm._e()],2),_c('list',{attrs:{"config":{
      'data': _vm.model,
      'keyFn': function (item) { return item.guid; },
      'direction': _vm.ListDirection.Vertical
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var group = ref.item;
    var index = ref.index;
return [_c('a-card',{staticClass:"trigger-group"},[_c('div',{staticClass:"flex gap-2 flex-col xs:flex-row xs:items-center xs:justify-between",attrs:{"slot":"title"},slot:"title"},[_c('div',[_c('a-button',{staticClass:"mr-1",class:{'active': _vm.isExtraGroupActionButtonActive(group.value)},attrs:{"icon":"setting","title":_vm.$t('trigger_action_group_extra_setting_button_title')},on:{"click":function($event){return _vm.prepareActionGroupExtraSettings(group)}}}),_vm._v(" "+_vm._s(_vm.$t('action_group_index', [index+1]))+" ")],1),_c('div',{staticClass:"flex items-center gap-3"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index,"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('a-button',{staticClass:"text-danger hover:opacity-75",attrs:{"icon":"delete","type":"link","disabled":_vm.disabled},on:{"click":function($event){_vm.model = _vm.model.filter(function (filteredItem, filteredIndex) { return filteredIndex !== index; })}}})],1)]),_c('action-group-items',{attrs:{"disabled":_vm.disabled,"has-form":_vm.hasForm,"filtered-actions":_vm.filteredActions,"trigger-factory":_vm.triggerFactory,"selected-form-struct":_vm.selectedFormStruct},model:{value:(group.value.actions),callback:function ($$v) {_vm.$set(group.value, "actions", $$v)},expression:"group.value.actions"}})],1),(index !== _vm.model.length - 1)?_c('div',{staticClass:"text-primary text-center font-bold uppercase mt-5"},[(_vm.actionExecuteType === 'All')?[_vm._v(" "+_vm._s(_vm.$t('trigger_group_and'))+" ")]:_vm._e(),(_vm.actionExecuteType === 'Random')?[_vm._v(" "+_vm._s(_vm.$t('trigger_group_or'))+" ")]:_vm._e()],2):_vm._e()]}}])}),(!_vm.disabled)?_c('div',{staticClass:"text-center mt-5"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addActionGroup(_vm.model)}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_setup_button_add_action_group'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }