





















import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import RanksHandlerModule from "@/includes/logic/Modules/modules/modules/RanksHandlerModule/RanksHandlerModule";

type UserRank = {
  title: string,
  reputation: number
  xp: number
}

@Component({
  components: {
    ConditionInvertSettings,
  }
})
export default class ConditionRank extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get rankValue(): string {
    const value = this.conditionData.value[0]

    return value === 0 ? '1' : value
  }

  set rankValue(value:string) {
    this.conditionData.value.splice(0, 1, value)
  }

  ranks(): Array<SelectOption> {
    const ranksModule = ModuleManagerState?.modules.find(m => m instanceof RanksHandlerModule) as RanksHandlerModule

    if (ranksModule) {
      return ranksModule.params.user_ranks
        .map((rank, rankIndex) => ({
          label: rank.title,
          value: String(rankIndex + 1)
        }))
    } else {
      return []
    }
  }
}
