import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WarnActionsWrapper from "./WarnActionsWrapper.vue";
import { ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import SilentWarnAction from "./actions/SilentWarnAction";
import WarnAction from "./actions/WarnAction";

const action: EntityData = {
  'setupView': WarnActionsWrapper,
  'structure': WarnAction.structure,
  ...entityDataTitleKeyFields(ActionsWrapperType.WarnActionsWrapper, 'trigger_action_'),
  extraProps: {
    actions: [
      SilentWarnAction,
      WarnAction,
    ]
  }
}

export default action
