import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ReactionEmojiEnum } from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/types";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import AddMessageReactionActionSetupView from "./AddMessageReactionActionSetupView.vue";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': AddMessageReactionActionSetupView,
  'structure': {
    'type': ActionsType.AddMessageReactionAction,
    'target': "Caller",
    'time'  : 0, // Секунд
    'chance': 100,
    'reply': false,
    'reaction': ReactionEmojiEnum.ThumbsUp,
  },
  ...entityDataTitleKeyFields(ActionsType.AddMessageReactionAction, 'trigger_action_'),
}

export default action
