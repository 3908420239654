import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../logic/helpers";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': () => import('./SetBanLevelActionSetupView.vue'),
  'structure': {
    'type': ActionsType.SetBanLevelAction,
    'target': "Caller",
    'value' : 0,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SetBanLevelAction, 'trigger_action_'),
  limited: () => store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
}

export default action
