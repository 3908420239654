import { IAIIntegrationModule, IAiIntegrationModuleBuilder } from "../IAiIntegrationModule";
import AiIntegrationModuleYandexGptSetup from "./AiIntegrationModuleYandexGptSetup.vue";

export class AiIntegrationModuleYandexGpt implements IAIIntegrationModule {
  constructor(
      public id: string,
      public service_account_id: string,
      public created_at: string,
      public key_algorithm: string,
      public public_key: string,
      public private_key: string,
  ) {
  }

  get paramsFilled() {
    return !!this.id.trim() &&
        !!this.service_account_id.trim() &&
        !!this.created_at.trim() &&
        !!this.key_algorithm.trim() &&
        !!this.public_key.trim() &&
        !!this.private_key.trim()
  }

  validate(): Promise<boolean> {
    if (this.paramsFilled) {
      return Promise.resolve(true)
    } else {
      return Promise.reject('The settings are not loaded')
    }
  }

  resetFields() {
    this.id = ''
    this.service_account_id = ''
    this.created_at = ''
    this.key_algorithm = ''
    this.public_key = ''
    this.private_key = ''
  }
}

class AiIntegrationModuleYandexGptBuilder implements IAiIntegrationModuleBuilder<AiIntegrationModuleYandexGpt> {
  provider = 'AiIntegrationModuleYandexGpt'

  setupView = AiIntegrationModuleYandexGptSetup

  getParams(): AiIntegrationModuleYandexGpt {
    return new AiIntegrationModuleYandexGpt(
        '',
        '',
        '',
        '',
        '',
        '',
    )
  }
}

export const aiIntegrationModuleYandexGptBuilder = new AiIntegrationModuleYandexGptBuilder()
