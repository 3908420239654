import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SetPointsActionSetupView from "@/components/TriggerSetup/actions/SetPointsAction/SetPointsActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': SetPointsActionSetupView,
  'structure': {
    'type': ActionsType.SetPointsAction,
    'target': "Caller",
    'value' : 1, // can be positive and negative
    // from  : 0, // can be positive and negative
    // to    : 0,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SetPointsAction, 'trigger_action_'),
  limited: () => store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
}

export default action
