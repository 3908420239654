import { CredentialsLogic } from "@/includes/logic/Credentials/CredentialsLogic";
import { aiIntegrationModuleOpenAiBuilder } from "@/includes/logic/Credentials/providers/AiIntegrationModuleOpenAi/model";
import { aiIntegrationModuleYandexGptBuilder } from "@/includes/logic/Credentials/providers/AiIntegrationModuleYandexGpt/model";
import {
  CreateCredentialReq,
  CreatedCredential, DeleteChatCredentialReq,
  GetCredentialsReq,
  GetCredentialsRes, UpdateCredentialReq
} from "@/includes/types/Credentials";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

class ChatkeeperCredentials extends CredentialsLogic {
  createCredentialApi(payload: CreateCredentialReq): AxiosPromise<CreatedCredential> {
    return PostApi.sendRequest<CreatedCredential>('tg', 'createchatcredential', payload)
  }

  deleteCredentialApi(payload: DeleteChatCredentialReq): AxiosPromise<void> {
    return PostApi.sendRequest('tg', 'deletechatcredential', payload)
  }

  getCredentialsApi(payload: GetCredentialsReq): AxiosPromise<GetCredentialsRes> {
    return PostApi.sendRequest('tg', 'getchatcredentials', payload)
  }

  updateCredentialsApi(payload: UpdateCredentialReq): AxiosPromise<CreatedCredential> {
    return PostApi.sendRequest('tg', 'updatechatcredential', payload)
  }
}

export const chatkeeperCredentials = new ChatkeeperCredentials([
  aiIntegrationModuleOpenAiBuilder,
  aiIntegrationModuleYandexGptBuilder
])
