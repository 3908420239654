























import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/new/ConditionWrapperMixin";
import { ConditionSetupView } from '../../../logic/mixins/condition-setup-view.mixin'
import RegexpValidatorContainer from "@/components/RegexpValidatorContainer.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: { RegexpValidatorContainer }
})
export default class RegexpConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
