












































































































import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import LlmConditionsMixin from "@/components/TriggerSetup/logic/mixins/LlmConditionsMixin";
import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";

import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  data() {
    return {
      MediaEditorMode
    }
  },
  'components': {
    ActionTargets,
    ActionChance,
    MultiMessageEditorWithMediaInput
  }
})
export default class LlmConvertRequestActionSetupView extends Mixins(ActionSetupView, LlmConditionsMixin, InputSetups, Placeholders) {
}
