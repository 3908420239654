





































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings,
    SelectInput,
    ChipsInput
  }
})
export default class ExpressionConditionSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin) {
  get stringCheckCondition() {
    return this.conditionData.value[0]
  }

  set stringCheckCondition(value: string) {
    this.conditionData.value.splice(0, 1, value)
  }

  get expressions() {
    return this.conditionData.value.slice(1)
  }

  set expressions(values: Array<string>) {
    this.conditionData.value.splice(1, this.conditionData.value.length, ...values)
  }

  get stringCheckConditionOptions(): Array<SelectOption> {
    return [
      { label: this.$t('base_super_list_type_contains'), value: 'Contains' },
      { label: this.$t('base_super_list_type_equals'), value: 'Equals' },
      { label: this.$t('base_super_list_type_start_with'), value: 'StartWith' },
      { label: this.$t('base_super_list_type_end_with'), value: 'EndWiths' },
      { label: this.$t('base_super_list_type_reg_exp'), value: 'RegExp' },
      { label: this.$t('base_super_list_type_word_equals'), value: 'WordEquals' },
    ]
  }
}
