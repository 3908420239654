

























import { AiIntegrationModuleYandexGpt } from "./model";
import { errorNotification } from "@/includes/NotificationService";

import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import MediaUploader from "piramis-base-components/src/components/MediaUploader/MediaUploader.vue";
import { MediaFileData } from "piramis-base-components/src/components/File/types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    MediaUploader,
    ConfigField
  }
})
export default class AiIntegrationModuleYandexGptSetup extends Mixins(ModelSetter) {
  @VModel() model!: AiIntegrationModuleYandexGpt

  handleFile(file: MediaFileData) {
    const onReaderLoad = (event: ProgressEvent<FileReader>) => {
      if (event?.target?.result && typeof event.target.result === 'string') {
        try {
          this.model = Object.assign(this.model, JSON.parse(event.target.result));
        } catch (error) {
          errorNotification(error)
        }
      }
    }

    const reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(file.blobFile);
  }
}
