import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ChangeReputationIntervalActionSetupView
  from "@/components/TriggerSetup/actions/ChangeReputationIntervalAction/ChangeReputationIntervalActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': ChangeReputationIntervalActionSetupView,
  'structure': {
    'type': ActionsType.ChangeReputationIntervalAction,
    'target': "Caller",
    'count' : 0, // can be positive and negative
    'from': 1,
    'to': 1,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ChangeReputationIntervalAction, 'trigger_action_'),
}

export default action
