import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import store from "@/store/store";

import BanAction from './BanAction'
import ChangeReputationIntervalAction from './ChangeReputationIntervalAction'
import ChangeTriggerActiveStateAction from './ChangeTriggerActiveStateAction'
import ChangeXpIntervalAction from './ChangeXpIntervalAction'
import DeleteMessageAction from './DeleteMessageAction'
import ForwardMessageToTargetAction from './ForwardMessageToTargetAction'
import ForwardReplyMessageToTargetAction from './ForwardReplyMessageToTargetAction'
import KickAction from './KickAction'
import MuteAction from './MuteAction'
import SendFormResultToTarget from './SendFormResultToTarget'
import SendFormToChat from './SendFormToChat'
import SendFormToPrivate from './SendFormToPrivate'
import SendMessageAction from './SendMessageAction'
import SendMessageToTargetAction from './SendMessageToTargetAction'
import SendPrivateMessageAction from './SendPrivateMessageAction'
import SendMessageToCalculatedTargetAction from "./SendMessageToCalculatedTargetAction";
import SetPointsAction from './SetPointsAction'
import SetReputationAction from './SetReputationAction'
import SetXpAction from './SetXpAction'
import UnbanAction from './UnbanAction'
import UnmuteAction from './UnmuteAction'
import ChangePointsIntervalAction from './ChangePointsIntervalAction'
import TriggerWarnAction from './TriggerWarnAction'
import WarnActionsWrapper from "./WarnActionsWrapper";
import PinMessageAction from "./PinMessageAction";
import UnPinMessageAction from "./UnPinMessageAction";
import AddMessageReactionAction from "./AddMessageReactionAction";
import AchievementsActionsWrapper from "./AchievementsActionsWrapper";
import CompleteCaptchaQuestAction from "./CompleteCaptchaQuestAction";
import DeleteAllMessageAction from "./DeleteAllMessageAction";
import ScheduleDeleteMessageAction from "./ScheduleDeleteMessageAction";

import AddAchievementTriggerAction from "./AchievementsActionsWrapper/actions/AddAchievementTriggerAction";
import DeleteAchievementTriggerAction from "./AchievementsActionsWrapper/actions/DeleteAchievementTriggerAction";
import SilentWarnAction from "./WarnActionsWrapper/actions/SilentWarnAction";
import WarnAction from "./WarnActionsWrapper/actions/WarnAction";
import SetBanLevelAction from "./SetBanLevelAction";

import ChangeBanLevelIntervalAction from "./ChangeBanLevelIntervalAction";

import HttpRequestAction from "./HttpRequestAction";

import LlmConvertRequestAction from "./LlmConvertRequestAction";
import LlmSwitchRequestAction from "./LlmSwitchRequestAction";

import { chain } from "lodash";

export function getTriggerActions(): { [key: string]: EntityData } {
  return chain([
    SendMessageAction,
    DeleteMessageAction,
    KickAction,
    SendPrivateMessageAction,
    BanAction,
    UnbanAction,
    MuteAction,
    UnmuteAction,
    ChangeReputationIntervalAction,
    SetReputationAction,
    ChangePointsIntervalAction,
    SetPointsAction,
    ChangeXpIntervalAction,
    SetXpAction,
    ChangeTriggerActiveStateAction,

    SilentWarnAction,
    WarnAction,
    TriggerWarnAction,
    PinMessageAction,
    UnPinMessageAction,
    // ULTIMATE
    SendMessageToTargetAction,
    ForwardReplyMessageToTargetAction,
    ForwardMessageToTargetAction,
    SendMessageToCalculatedTargetAction,

    AddAchievementTriggerAction,
    DeleteAchievementTriggerAction,
    // FORMS
    SendFormToChat,
    SendFormToPrivate,
    SendFormResultToTarget,
    AddMessageReactionAction,
    CompleteCaptchaQuestAction,
    DeleteAllMessageAction,
    ScheduleDeleteMessageAction,

    ChangeBanLevelIntervalAction,
    SetBanLevelAction,
    HttpRequestAction,

    LlmConvertRequestAction,
    LlmSwitchRequestAction
  ])
      .keyBy('structure.type')
      .value()
}

export const actionsWrappers = [
  AchievementsActionsWrapper,
  WarnActionsWrapper,
]

export const actionGroupsTypes: Record<string, Array<EntityData>> = {
  'formActions': [
    SendFormToChat,
    SendFormToPrivate,
    SendFormResultToTarget
  ],
  'messageActions': [
    SendMessageAction,
    DeleteMessageAction,
    DeleteAllMessageAction,
    ScheduleDeleteMessageAction,
    SendPrivateMessageAction,
    SendMessageToTargetAction,
    ForwardReplyMessageToTargetAction,
    ForwardMessageToTargetAction,
    SendMessageToCalculatedTargetAction,
    PinMessageAction,
    UnPinMessageAction
  ],
  'userActions': [
    KickAction,
    BanAction,
    UnbanAction,
    MuteAction,
    UnmuteAction
  ],
  'userPointsActions': [
    ChangeReputationIntervalAction,
    ChangePointsIntervalAction,
    ChangeXpIntervalAction,
    SetXpAction,
    SetPointsAction,
    SetReputationAction,
    // CalculatePropertyTriggerAction
  ],
  'otherActions': [
    ChangeTriggerActiveStateAction,
    WarnActionsWrapper,
    ChangeBanLevelIntervalAction,
    SetBanLevelAction,
    AddMessageReactionAction,
    AchievementsActionsWrapper,
    CompleteCaptchaQuestAction,
    HttpRequestAction,

    ...store.getters.isChatSet && store.state.chatState.chat!.version >= 6000 ? [
      LlmConvertRequestAction,
      LlmSwitchRequestAction,
    ] : [],
  ]
}
