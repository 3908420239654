import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SilentWarnActionSetupView from "./SilentWarnActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../../../logic/helpers";

const action: EntityData = {
  'setupView': SilentWarnActionSetupView,
  'structure': {
    'type': ActionsType.SilentWarnAction,
    'target': "Caller",
    'count': 1,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SilentWarnAction, 'trigger_action')
}

export default action
