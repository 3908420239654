import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ChangeXpIntervalActionSetupView
  from "@/components/TriggerSetup/actions/ChangeXpIntervalAction/ChangeXpIntervalActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': ChangeXpIntervalActionSetupView,
  'structure': {
    'type': ActionsType.ChangeBanLevelIntervalAction,
    'target': "Caller",
    // count : 0, // can be positive and negative
    'from'  : 1, // can be positive and negative
    'to'    : 1,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ChangeBanLevelIntervalAction, 'trigger_action_'),
  limited: () => store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
}

export default action
