

















import { AiIntegrationModuleOpenAi } from "@/includes/logic/Credentials/providers/AiIntegrationModuleOpenAi/model";

import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    TextInput
  }
})
export default class AiIntegrationModuleOpenAiSetup extends Mixins(ModelSetter) {
  @VModel() model!: AiIntegrationModuleOpenAi
}
