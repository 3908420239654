var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"achievements-actions-wrapper"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'type',
        'prefix': 'achievements_actions_wrapper_',
        'options': _vm.actionOptions,
        'multiple': false,
        clearable: false
      },
    }}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }