

























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";
import { ChatConfig } from "@/includes/types/Chat/types";
import GroupTopicsMixin from "@/mixins/GroupTopicsMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class ThreadIdSetupView extends Mixins(ConditionSetupView, GroupTopicsMixin) {

  get threadId():Array<string> {
    return this.conditionData.value
  }

  set threadId(value:Array<string>) {
    this.conditionData.value = value
  }

}
