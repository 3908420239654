import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { SelectOption } from "piramis-base-components/src/logic/types";

import Vue from "vue";
import { cloneDeep, snakeCase } from "lodash";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class ActionWrapperMixin extends Vue {
  @Prop() actions!: Array<EntityData>

  @Prop() actionType!: ActionsType

  @Emit()
  updateAction(value: EntityData | undefined) {
    return value
  }

  get type() {
    return this.actionType
  }

  set type(value: ActionsType) {
    this.updateAction(cloneDeep(this.actions.find(c => c.structure.type === value)))
  }

  get actionOptions():Array<SelectOption> {
    return this.actions.map(action => ({
      label: this.$t(`trigger_action_${ snakeCase(action.structure.type) }`),
      value: action.structure.type
    }))
  }
}
