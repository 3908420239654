


































































import ActionTargets from '@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue'
import ActionChance from '@/components/TriggerSetup/components/ActionChance/ActionChance.vue'
import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'

import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
    ActionChance
  }
})
export default class ActionInterval extends Mixins<ActionSetupView>(ActionSetupView, ModelSetter) {
  isShowIntervalVisible = false

  get computedCount() {
    return this.model.from
  }

  set computedCount(value: number) {
    this.model.from = value
    this.model.to = value
  }

  created() {
    this.isShowIntervalVisible = this.model.from !== this.model.to
  }

}
