

















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import WarnAction from "./actions/WarnAction";
import SilentWarn from "./actions/SilentWarnAction";
import ActionWrapperMixin from "../ActionWrapperMixin";

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from "lodash";

@Component
export default class WarnActionsWrapper extends Mixins(ActionSetupView, ActionWrapperMixin) {
  get sendWarnMessage() {
    return this.actionType === ActionsType.WarnAction
  }

  set sendWarnMessage(state: boolean) {
    if (state){
      this.updateAction(cloneDeep(WarnAction))
    } else {
      this.updateAction(cloneDeep(SilentWarn))
    }
  }
}
