import { HttpRequestConditionValue } from "./types";

import { HttpMethodEnum, HttpResponseTypeEnum } from "piramis-base-components/src/shared/types/HttpRequest";

export function getHttpRequestValue(): HttpRequestConditionValue {
  return {
    request: {
      url: '',
      method: HttpMethodEnum.Get,
      body: '{\n\n}',
      headers: {},
      response_type: HttpResponseTypeEnum.Json
    },
    path: '',
    cache: true
  }
}
