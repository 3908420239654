import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import HttpRequestConditionsWrapper from "./HttpRequestConditionsWrapper.vue";
import HttpRequestContains from "./conditions/HttpRequestContains";
import HttpRequestEquals from "./conditions/HttpRequestEquals";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const condition: EntityData = {
  'setupView': HttpRequestConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.HttpRequestConditionsWrapper, 'trigger_condition_'),
  structure: HttpRequestContains.structure,
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG,
  extraProps: {
    conditions: [
      HttpRequestContains,
      HttpRequestEquals,
    ],
  }
}

export default condition
