








































































































































import CreateAction from "@/components/TriggerSetup/components/CreateAction/CreateAction.vue";
import {
  ActionsType,
  TriggerActionGroup
} from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { actionGroupsTypes, actionsWrappers } from "@/components/TriggerSetup/actions";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { TriggerFactory } from "@/components/TriggerSetup/logic/trigger/trigger-factory";
import { isEnumValue } from "@/includes/types/util.types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import List from "piramis-base-components/src/components/Pi/components/List/List.vue";
import { ListDirection } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import { MessageEditorWithMediaData } from "piramis-base-components/src/components/MessageEditorWithMedia/types";
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, VModel, Prop, Mixins } from 'vue-property-decorator';
import { cloneDeep, memoize } from "lodash";

type CurrentActionInfo = {
  action: IterableListItem<EntityData> | null
}

@Component({
  data() {
    return {
      ListDirection
    }
  },
  components: {
    ActionChance,
    List,
    CreateAction,
    ConfigField,
    BlockMovingControls,
    TextInput
  }
})
export default class ActionGroupsItem extends Mixins(ModelSetter, TariffsTagsHelper) {
  @VModel() model!: TriggerActionGroup['actions']

  @Prop({ type: Array, required: true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) hasForm!: boolean

  @Prop({ type: Array, required: false, default: () => [] }) selectedFormStruct!: Array<MessageEditorWithMediaData>

  @Prop() triggerFactory!: TriggerFactory

  currentActionInfo: CurrentActionInfo = this.initCurrentActionState()

  isActionExtraSettingModalOpen = false

  actionWrapper = memoize((type: ActionsType) => {
    return actionsWrappers.find(w => w.extraProps?.actions?.some((c: EntityData) => c.structure.type === type))
  })

  updateAction(action: IterableListItem<EntityData>, newAction: EntityData | undefined) {
    if (newAction) {
      action.value = newAction
    }
  }

  get currentActionForUserAction() {
    if (this.currentActionInfo.action) {
      const actions = [
        ActionsType.MuteAction,
        ActionsType.BanAction,
        ActionsType.KickAction,
        ActionsType.UnmuteAction,
        ActionsType.UnbanAction
      ]

      return actions.includes(this.currentActionInfo.action.value.structure.type)
    }

    return false
  }

  afterModalClose() {
    this.currentActionInfo = this.initCurrentActionState()
  }

  initCurrentActionState(): CurrentActionInfo {
    return {
      action: null
    }
  }

  createAction(key: EntityData['key']): void {
    const action: any | undefined = Object.values(actionGroupsTypes).flat().find(e => e.key === key)

    if (action) {
      if (isEnumValue(ActionsType, key) && [ ActionsType.SendFormToPrivate, ActionsType.SendFormToChat, ActionsType.SendFormResultToTarget ].includes(key)) {
        if (this.selectedFormStruct) {
          action.structure.text = this.selectedFormStruct.slice()
        }
      }

      this.model.push(this.triggerFactory.createAction(action.structure))
    }
  }

  setTriggerActionModel() {
    const { action } = this.currentActionInfo

    if (action) {
      const groupAction = this.model.find(a => a.guid === action.guid)

      if (groupAction) {
        if (this.isActionWrapper(groupAction) && this.isActionWrapper(action)) {
          groupAction.value.structure.currentAction.chance = action.value.structure.currentAction.chance
          groupAction.value.structure.currentAction.targetSource = action.value.structure.currentAction.targetSource
        } else {
          groupAction.value.structure.chance = action.value.structure.chance
          groupAction.value.structure.targetSource = action.value.structure.targetSource
        }
      }
    }

    this.isActionExtraSettingModalOpen = false
  }

  isExtraActionButtonActive(action: IterableListItem<EntityData>) {
    if (this.isActionWrapper(action)) {
      return action.value.structure.currentAction.chance !== 100
    } else {
      return action.value.structure.chance !== 100
    }
  }

  isActionWrapper(action: IterableListItem<EntityData>) {
    return action.value.structure.currentAction !== undefined
  }

  prepareCurrentActionExtraSettings(actionModel: CurrentActionInfo['action']) {
    this.currentActionInfo.action = cloneDeep(actionModel)

    this.isActionExtraSettingModalOpen = true
  }
}
