




















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionWrapperMixin from "@/components/TriggerSetup/actions/ActionWrapperMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
})
export default class AchievementsActionsWrapper extends Mixins(ActionSetupView, ActionWrapperMixin) {

}
