import { IAIIntegrationModule, IAiIntegrationModuleBuilder } from "./providers/IAiIntegrationModule";
import {
  CreateCredentialReq, CreatedCredential, DeleteChatCredentialReq,
  GetCredentialsReq, GetCredentialsRes, UpdateCredentialReq
} from "@/includes/types/Credentials";

import { keyBy } from "lodash";
import { AxiosPromise } from "axios";

export abstract class CredentialsLogic {
  constructor(
      private builders: Array<IAiIntegrationModuleBuilder<IAIIntegrationModule>>) {
  }

  get keyedBuilders() {
    return keyBy(this.builders, 'provider')
  }

  abstract getCredentialsApi(payload: GetCredentialsReq): AxiosPromise<GetCredentialsRes>
  abstract createCredentialApi(payload: CreateCredentialReq): AxiosPromise<CreatedCredential>
  abstract deleteCredentialApi(payload: DeleteChatCredentialReq): AxiosPromise<void>
  abstract updateCredentialsApi(payload: UpdateCredentialReq): AxiosPromise<CreatedCredential>
}
