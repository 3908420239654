









































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { ToxicFilterModeEnum } from "@/includes/logic/Modules/modules/modules/ToxicFilterHandlerModule/types";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import { InputSetups } from "@/mixins/input-setups";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings,
    SelectInput
  }
})
export default class MessageToxicConditionSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin, InputSetups) {
  get mode() {
    return this.conditionData.value[0]
  }

  set mode(value: ToxicFilterModeEnum) {
    this.conditionData.value.splice(0, 1, value)
  }

  get percent() {
    return this.conditionData.value[1]
  }

  set percent(value: Array<string>) {
    this.conditionData.value.splice(1, 1, value)
  }

  get percentOptions(): Array<SelectOption> {
    return this.aiDetectPercentOptions.map(o => ({
      ...o,
      value: o.value.toString()
    }))
  }
}
