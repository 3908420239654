










































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { errorNotification } from "@/includes/NotificationService";
import { HttpRequestConditionValue } from "../types";
import { getHttpRequestValue } from "../logic";

import HttpRequestSetting from 'piramis-base-components/src/shared/components/HttpRequest/HttpRequestSetting.vue'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { HttpMethodEnum, HttpRequest } from "piramis-base-components/src/shared/types/HttpRequest";
import { SelectOption } from "piramis-base-components/src/logic/types";
import { AvailableBaseTabs } from "piramis-base-components/src/shared/components/HttpRequest/types";

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  'components': {
    HttpRequestSetting,
    ConditionInvertSettings
  }
})
export default class HttpRequestConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  httpRequest: HttpRequestConditionValue = getHttpRequestValue()

  @Watch('httpRequest', { deep: true })
  change(val: HttpRequestConditionValue) {
    try {
      this.conditionData.value.splice(0, 1, JSON.stringify(val))
    } catch (error) {
      errorNotification(error)
    }
  }

  get values() {
    return this.conditionData.value.slice(1)
  }

  set values(values: Array<string>) {
    this.conditionData.value.splice(1, this.conditionData.value.slice(1).length, ...values)
  }

  requestInput(value: HttpRequest) {
    this.httpRequest.request = value
  }

  get httpMethods(): Array<SelectOptionData> {
    return [
      {
        label: HttpMethodEnum.Get,
        value: HttpMethodEnum.Get,
      },
      {
        label: HttpMethodEnum.Post,
        value: HttpMethodEnum.Post,
      },
      {
        label: HttpMethodEnum.Put,
        value: HttpMethodEnum.Put,
      },
      {
        label: HttpMethodEnum.Delete,
        value: HttpMethodEnum.Delete,
      }
    ]
  }

  get responseTypeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('http_request_response_type_empty').toString(),
        value: "Empty"
      },
      {
        label: "TEXT",
        value: "Text"
      },
      {
        label: "JSON",
        value: "Json"
      },
      {
        label: "XML",
        value: "Xml"
      },
    ]
  }

  get requestFormatOptions(): Array<SelectOption> {
    return [
      {
        label: 'Json',
        value: 'json'
      },
      {
        label: 'Form',
        value: 'form'
      },
      {
        label: 'Xml',
        value: 'xml'
      },
      {
        label: 'Plain',
        value: 'plain'
      }
    ]
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('http_request_tab_headers').toString(),
        value: AvailableBaseTabs.headers,
        helpMessage: this.$t('http_request_tab_headers_help_message').toString()
      },
      {
        label: this.$t('http_request_tab_body').toString(),
        value: AvailableBaseTabs.body,
        helpMessage: this.$t('http_request_tab_body_chatkeeper_help_message').toString()
      },
    ]
  }

  created() {
    try {
      this.httpRequest = JSON.parse(this.conditionData.value[0])
    } catch (error) {
      errorNotification(error)
    }
  }
}
