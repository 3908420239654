























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class RequestUserTypeSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get valueOption() {
    return this.conditionData.value[0]
  }

  set valueOption(value: string) {
    this.conditionData.value.splice(0, 1, value)
  }

  get userTypeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('request_user_type_value_actor').toString(),
        value: ''
      },
      {
        label: this.$t('request_user_type_value_target').toString(),
        value: 'Target'
      },
    ]
  }
}
