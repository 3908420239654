










import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import EntitySelect from "@/components/TriggerSetup/components/EntitySelect/EntitySelect.vue";

import { UseFields } from "piramis-base-components/src/components/Pi/index";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  'components': {
    EntitySelect
  },
})
export default class CreateAction extends Mixins(UseFields) {
  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() readonly actions!: any

  @Emit()
  selectAction(actionKey: EntityData['key']): EntityData['key'] {
    return actionKey
  }
}
