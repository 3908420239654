var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-regexp-placeholder-setup-view"},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('a-modal',{attrs:{"after-close":function () { return _vm.pattern = _vm.basePatternModel(); },"ok-text":_vm.$t('add'),"ok-button-props":{
        props: {
          disabled: invalid
        }
      }},on:{"ok":_vm.okClick},model:{value:(_vm.isAddPatternModalOpen),callback:function ($$v) {_vm.isAddPatternModalOpen=$$v},expression:"isAddPatternModalOpen"}},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.pattern.value,
            'key': 'pattern',
            'prefix': 'check_regexp_placeholder_',
            'validation': "required"
          }
        }}}),_c('text-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.pattern.value,
            'key': 'placeholder',
            'prefix': 'custom_',
            'validation': 'required|placeholder_validator'
          }
        }}})],1)]}}])}),_c('config-field',{staticClass:"my-3 py-1",attrs:{"title":_vm.$t('check_regexp_placeholder_value')}},[_c('a-button',{attrs:{"slot":"titleButton","size":"small","icon":"plus"},on:{"click":function($event){_vm.isAddPatternModalOpen = true}},slot:"titleButton"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]),_c('a-list',{attrs:{"data-source":_vm.frontendConditions},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{},[_c('a-list-item-meta',{scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('check_regexp_placeholder_item_pattern', [item.value.pattern]))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('check_regexp_placeholder_item_placeholder', [item.value.placeholder]))}})]},proxy:true}],null,true)}),_c('div',{staticClass:"flex gap-2"},[_c('a-button',{staticClass:"text-warning hover:opacity-75",attrs:{"type":"link","size":"small","icon":"edit"},on:{"click":function($event){return _vm.editClick(item)}}}),_c('a-button',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"link","size":"small","icon":"delete"},on:{"click":function($event){return _vm.removeClick(item.guid)}}})],1)],1)}}])})],1),_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'contextType',
        'prefix': 'check_regexp_placeholder_',
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }