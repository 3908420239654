import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import LlmSwitchRequestActionSetupView from "./LlmSwitchRequestActionSetupView.vue";

const action: EntityData = {
  'setupView': LlmSwitchRequestActionSetupView,
  'structure': {
    'type': ActionsType.LlmSwitchRequestAction,
    'chance': 100,
    'credential': '',
    'model': '',
    'instruction': '',
    'request': '{message.start(2)}',
    'temperature': 0.3,
    'maxTokens': 500,
    'asReply': 'Reply',
    'variants': {},
    'notSelected': [],
  },
  ...entityDataTitleKeyFields(ActionsType.LlmSwitchRequestAction, 'trigger_action_'),
}

export default action

