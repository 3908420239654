import { IAIIntegrationModule, IAiIntegrationModuleBuilder } from "../IAiIntegrationModule";
import AiIntegrationModuleOpenAiSetup from "./AiIntegrationModuleOpenAiSetup.vue";

export class AiIntegrationModuleOpenAi implements IAIIntegrationModule {
  constructor(
      public token: string
  ) {
  }

  validate(): Promise<boolean> {
    if (this.token.trim()) {
      return Promise.resolve(true)
    } else {
      return Promise.reject('Fill token')
    }
  }
}

class AiIntegrationModuleOpenAiBuilder implements IAiIntegrationModuleBuilder<AiIntegrationModuleOpenAi> {
  provider = 'AiIntegrationModuleOpenAi'

  setupView = AiIntegrationModuleOpenAiSetup

  getParams(): AiIntegrationModuleOpenAi {
    return new AiIntegrationModuleOpenAi('')
  }

  validate(): Promise<boolean> {
    return Promise.resolve(true);
  }
}

export const aiIntegrationModuleOpenAiBuilder = new AiIntegrationModuleOpenAiBuilder()
