import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { getHttpRequestValue } from "@/components/TriggerSetup/conditions/new/HttpRequestConditionsWrapper/conditions/logic";
import HttpRequestActionSetupView from "./HttpRequestActionSetupView.vue";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': HttpRequestActionSetupView,
  'structure': {
    'type': ActionsType.HttpRequestAction,
    'target': "Caller",
    'time'  : 0, // Секунд
    'chance': 100,
    'reply': false,
    'request': getHttpRequestValue().request
  },
  ...entityDataTitleKeyFields(ActionsType.HttpRequestAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG, }

export default action
