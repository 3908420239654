import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ChangeXpIntervalActionSetupView
  from "@/components/TriggerSetup/actions/ChangeXpIntervalAction/ChangeXpIntervalActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': ChangeXpIntervalActionSetupView,
  'structure': {
    'type': ActionsType.ChangeXpIntervalAction,
    'target': "Caller",
    // count : 0, // can be positive and negative
    'from'  : 1, // can be positive and negative
    'to'    : 1,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ChangeXpIntervalAction, 'trigger_action_'),
}

export default action
