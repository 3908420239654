import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { getHttpRequestValue } from "../logic";
import HttpRequestConditionSetupView from "../components/HttpRequestConditionSetupView.vue";

const condition: EntityData = {
  'setupView': HttpRequestConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.HttpRequestEquals,
    ...newBaseCondition,
    value: [
        JSON.stringify(getHttpRequestValue())
    ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.HttpRequestEquals, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [
      ConditionZone.Message,
      ConditionZone.ReplyMessage,
      ConditionZone.RequestMessage,
      ConditionZone.ReactionMessage
    ]
  }
}

export default condition
